import React from "react";
import { Spin } from "antd";

const LoadingComponent = () => {
	return (
		<div class="page f1 center">
			<Spin />
		</div>
	);
};

export default LoadingComponent;
