import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import ActionTypes from './actionTypes';

export default handleActions({
    [ActionTypes.LOGOUT]: (state = {}, action) => {
        return {
            token: null,
            user: null
        } 
    },
    [ActionTypes.EMAIL_LOGIN_SUCCESS]: (state = {}, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    [ActionTypes.UPDATE_USER]: (state = {}, action) => {
        return update(state, {
            user: {
                $merge: action.payload
            }
        })
    },
    [ActionTypes.FIREBASE_LOGIN_SUCCESS]: (state = {}, action) => {
        return action.payload
    }
}, {
    token: null,
    user: null
})