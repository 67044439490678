import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import ActionsTypes from './actionTypes'

export default handleActions({
    [ActionsTypes.FETCH_LOCATIONS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                highlights: {
                    $set: action.payload
                },
                viewCount: {
                    $set: action.payload.viewCount
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_CHART_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                chart: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_MASKS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                masksProducts: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_SG_UPDATES_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                sgUpdates: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_GLOBAL_NEWS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            const data = action.payload.map((item) => {
                const tComps = item.timestamp.split(' · ')
                const xIndex = item.title.indexOf('】') + 1
                return {
                    timestamp: tComps[0],
                    source: tComps[1].replace('📌', ''),
                    country: item.title.slice(1, xIndex-1),
                    title: item.title.slice(xIndex),
                    url: item.sourceUrl
                }
            })
            return update(state, {
                globalNews: {
                    $set: data
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_COUNTRIES_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                countries: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_APPRECIATIONS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                appreciations: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.TOGGLE_LIKE_SUCCESS]: (state = {}, action) => {
        const { postId, likes, liked } = action.payload
        if (!state?.appreciations) {
            return state
        }
        const pIndex = state.appreciations.findIndex(item => item.id === postId);
        if (pIndex < 0) {
            return state
        }
        return update(state, {
            appreciations: {
                [pIndex]: {
                    $merge: {
                        likes, 
                        liked
                    }
                }
            }
        })
    },
    [ActionsTypes.GEN_USER_SUCCESS]: (state = {}, action) => {
        if (state.auth?.user) {
            return state
        }
        if (action.payload) {
            return update(state, {
                auth: {
                    $set: {
                        user: action.payload
                    }
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_VIEW_COUNT_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                viewCount: {
                    $set: action.payload.viewCount
                }
            })
        }
        return state
    },
    [ActionsTypes.TOGGLE_LOCAL_LIKE]: (state = {}, action) => {
        if (action.payload) {
            const pIndex = state.appreciations.findIndex(item => item.id === action.payload.postId);
            if (pIndex < 0) {
                return state
            }
            return update(state, {
                appreciations: {
                    [pIndex]: {
                        $apply: appreciation => {
                            return {
                                ...appreciation,
                                liked: !appreciation.liked,
                                likes: appreciation.likes + (appreciation.liked ? -1 : 1)
                            }
                        }
                    }
                }
            })
        }
        return state
    }
}, {
    viewCount: null,
    highlights: {},
    countries: [],
    masksProducts: {},
    chart: [],
    sgUpdates: [],
    globalNews: [],
    auth: {},
    appreciations: []
})