import gql from "graphql-tag";
import { PublicUserFragment } from 'shared/graphql/fragments'

export const HelpRequestFragment = gql`
	fragment HelpRequestFragment on helpRequest {
		id
		message
		creator {
			...PublicUserFragment
		}
		createdAt
		deletedAt
		topic
    }
    ${PublicUserFragment}
`;
