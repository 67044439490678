import { split, ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache, ApolloClient } from 'apollo-boost';
import { onError } from 'apollo-link-error';

import { GRAPHQL_URL } from "./constants";

export default token => {
	const authorization = token && `Bearer ${token}`;
	const headers = {
		Audience: 'heroes'
	};
	if (authorization) {
		headers.authorization = authorization
	}
	const httpLink = new HttpLink({
		uri: `https:${GRAPHQL_URL}`,
		headers
	});

	const wsLink = new WebSocketLink({
		uri: `wss:${GRAPHQL_URL}`,
		options: {
			reconnect: true,
			timeout: 30000,
			connectionParams: {
				headers
			}
		}
	});

	const requestLink = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query);
			return kind === 'OperationDefinition' && operation === 'subscription';
		},
		wsLink,
		httpLink
	);

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path }) => {
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				);
				return ''
			});
		}
		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
		}
	});
	const link = ApolloLink.from([errorLink, requestLink]);
	const client = new ApolloClient({
		link,
		cache: new InMemoryCache()
	});
	return {
		client,
		authorization
	};
}