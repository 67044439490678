import axios from 'axios';
import { AUTH_URL } from './constants';

const instance = axios.create({
	baseURL: AUTH_URL,
	timeout: 1000,
	headers: {
		audience: 'heroes'
	}
});

export default instance;
