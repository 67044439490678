import gql from "graphql-tag";

import { UserFragment } from "shared/graphql/fragments";

import { HelpRequestFragment } from "./fragments";

export const FETCH_USER_DATA = gql`
	query UserQuery($id: uuid!) {
		user_by_pk(id: $id) {
			...UserFragment
		}
	}
	${UserFragment}
`;

export const GET_HELP_TOPICS = gql`
	query {
		helpTopic {
			name
			comment
		}
	}
`;

export const INSERT_HELP_REQUEST_MUTATION = gql`
	mutation InsertHelpRequest($message: String!, $topic: helpTopic_enum!) {
		insert_helpRequest(objects: { message: $message, topic: $topic }) {
			returning {
				...HelpRequestFragment
			}
		}
	}
	${HelpRequestFragment}
`;

export const HELP_REQUESTS = gql`
	query HelpRequests(
		$topic: helpTopic_enum!
		$limit: Int = 10
		$offset: Int = 0
	) {
		helpRequest(
			where: { topic: { _eq: $topic } }
			limit: $limit
			offset: $offset
			order_by: { createdAt: desc }
		) {
			...HelpRequestFragment
		}
		helpRequest_aggregate(where: { topic: { _eq: $topic } }) {
			aggregate {
				count
			}
		}
	}
	${HelpRequestFragment}
`;

export const DISTANCE_QUERY = gql`
	query DistanceQuery($fromCode: String!, $toCode: String!) {
		distance(fromCode: $fromCode, toCode: $toCode) {
			distance
		}
	}
`;
