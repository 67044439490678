import { gql } from 'apollo-boost';

export const ImageFragment = gql`
	fragment ImageFragment on image {
		id
		size
		url
		createdAt
	}
`;

export const UserFragment = gql`
	fragment UserFragment on user {
		id
		firstName
		lastName
		phoneNumber
		phoneVerified
		avatar {
			id
			url
		}
		email
		emailVerified
		dateOfBirth
		createdAt
		newsSubscribed
		neighborhood {
			id
			name
		}
		postalCode
		neighborhood {
			id
			name
		}
		isVolunteer
        addressLastUpdated
        roadName
        viewPermission
	}
`;

export const ReactionFragment = gql`
	fragment ReactionFragment on reaction {
		targetId
		reactorId
		reaction
	}
`;

export const SelfReactionFragment = gql`
	fragment SelfReactionFragment on selfReaction {
		targetId
		reactorId
		reaction
	}
`;

export const PublicUserFragment = gql`
	fragment PublicUserFragment on userPublic {
		id
		firstName
		lastName
		avatar {
			id
			url
		}
		neighborhood {
			id
			name
		}
		isVolunteer
		roadName
		postalCode
	}
`;

export const CommentFragment = gql`
	fragment CommentFragment on comment {
		id
		message
		images {
			image {
				...ImageFragment
			}
		}
		author {
			...PublicUserFragment
		}
		targetId
		createdAt
		replies_aggregate {
			aggregate {
				count
			}
		}
		reactionCounts {
			targetId
			reaction
			count
		}
		reacted {
			...SelfReactionFragment
		}
	}
	${ImageFragment}
	${PublicUserFragment}
	${SelfReactionFragment}
`;

export {};
