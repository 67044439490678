const FETCH_CHART = 'FETCH_CHART'
const FETCH_CHART_SUCCESS = 'FETCH_CHART_SUCCESS'
const FETCH_CHART_FAILURE = 'FETCH_CHART_FAILURE'


const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'


const FETCH_MASKS = 'FETCH_MASKS'
const FETCH_MASKS_SUCCESS = 'FETCH_MASKS_SUCCESS'
const FETCH_MASKS_FAILURE = 'FETCH_MASKS_FAILURE'

const FETCH_SG_UPDATES = 'FETCH_SG_UPDATES'
const FETCH_SG_UPDATES_SUCCESS = 'FETCH_SG_UPDATES_SUCCESS'
const FETCH_SG_UPDATES_FAILURE = 'FETCH_SG_UPDATES_FAILURE'

const FETCH_GLOBAL_NEWS = 'FETCH_GLOBAL_NEWS'
const FETCH_GLOBAL_NEWS_SUCCESS = 'FETCH_GLOBAL_NEWS_SUCCESS'
const FETCH_GLOBAL_NEWS_FAILURE = 'FETCH_GLOBAL_NEWS_FAILURE'

const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'
const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE'

const FETCH_APPRECIATIONS = 'FETCH_APPRECIATIONS'
const FETCH_APPRECIATIONS_SUCCESS = 'FETCH_APPRECIATIONS_SUCCESS'
const FETCH_APPRECIATIONS_FAILURE = 'FETCH_APPRECIATIONS_FAILURE'

const GEN_USER = 'GEN_USER'
const GEN_USER_SUCCESS = 'GEN_USER_SUCCESS'
const GEN_USER_FAILURE = 'GEN_USER_FAILURE'

const TOGGLE_LIKE = 'TOGGLE_LIKE'
const TOGGLE_LIKE_SUCCESS = 'TOGGLE_LIKE_SUCCESS'
const TOGGLE_LIKE_FAILURE = 'TOGGLE_LIKE_FAILURE'

const TOGGLE_LOCAL_LIKE = 'TOGGLE_LOCAL_LIKE'

const FETCH_VIEW_COUNT = 'FETCH_VIEW_COUNT'
const FETCH_VIEW_COUNT_SUCCESS = 'FETCH_VIEW_COUNT_SUCCESS'
const FETCH_VIEW_COUNT_FAILURE = 'FETCH_VIEW_COUNT_FAILURE'

export default {
    FETCH_CHART, FETCH_CHART_SUCCESS, FETCH_CHART_FAILURE,
    FETCH_LOCATIONS, FETCH_LOCATIONS_SUCCESS, FETCH_LOCATIONS_FAILURE,
    FETCH_MASKS, FETCH_MASKS_SUCCESS, FETCH_MASKS_FAILURE,
    FETCH_SG_UPDATES, FETCH_SG_UPDATES_SUCCESS, FETCH_SG_UPDATES_FAILURE,
    FETCH_GLOBAL_NEWS, FETCH_GLOBAL_NEWS_SUCCESS, FETCH_GLOBAL_NEWS_FAILURE,
    FETCH_COUNTRIES, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILURE,
    FETCH_APPRECIATIONS, FETCH_APPRECIATIONS_SUCCESS, FETCH_APPRECIATIONS_FAILURE,
    GEN_USER, GEN_USER_SUCCESS, GEN_USER_FAILURE,
    TOGGLE_LIKE, TOGGLE_LIKE_SUCCESS, TOGGLE_LIKE_FAILURE, TOGGLE_LOCAL_LIKE,
    FETCH_VIEW_COUNT, FETCH_VIEW_COUNT_SUCCESS, FETCH_VIEW_COUNT_FAILURE
}