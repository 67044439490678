import React from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import { ServiceWorkerProvider } from "shared/utils/useServiceWorker";
import configStore from "config/configStore";
import ScrollToTop from "shared/components/ScrollToTop";
import LoadingComponent from "shared/components/LoadingComponent";
import configApollo from "config/configApollo";
import { useLazyQuery } from "@apollo/react-hooks";
import { updateUser } from 'auth/actions'

import "shared/shared.css";

import { FETCH_USER_DATA } from "neighbours/graphql/schema";

const AppContainer = React.lazy(() => import("./AppContainer"));
const AuthPage = React.lazy(() => import("auth/pages/AuthPage"));
const AppreciationDetailPage = React.lazy(() =>
	import("heroes/pages/AppreciationDetailPage")
);

const FAQPage = React.lazy(() => import("misc/pages/FAQPage"));
const PrivacyPolicyPage = React.lazy(() => import("misc/pages/PrivacyPolicyPage"));
const PostalInputPage = React.lazy(() => import("auth/pages/PostalInputPage"));
const ProfileSetupPage = React.lazy(() => import("auth/pages/ProfileSetupPage"));
const PhoneNumberInputPage = React.lazy(() => import("auth/pages/PhoneNumberInputPage"));
const RequestListPage = React.lazy(() => import("neighbours/pages/RequestListPage"));
const NeedHelpPage = React.lazy(() => import("neighbours/pages/NeedHelpPage"));

const StoreConfig = configStore();

const UpdateDataComponent = () => {
	const user = useSelector(state => state.auth?.user);
	const dispatch = useDispatch();

	const [
		loadUserData,
		{ called, data }
	] = useLazyQuery(FETCH_USER_DATA, { variables: { id: user?.id } });

	React.useEffect(() => {
		if (user?.id && !called) {
			loadUserData()
		}
	}, [user, called, loadUserData]);

	React.useEffect(() => {
		if (data && called) {
			if (data.user_by_pk) {
				updateUser(data.user_by_pk)(dispatch)
			}
		}
	}, [data, called, dispatch])
	return false;
}

const App = () => {
	const token = useSelector(state => state.auth?.token);
	const { client } = React.useMemo(() => {
		return configApollo(token);
	}, [token]);

	return (
		<ApolloProvider client={client}>
			<Router>
				<ScrollToTop />
				<UpdateDataComponent />
				<React.Suspense fallback={<LoadingComponent />}>
					<Switch>
						<Route path="/h/:postId" component={AppreciationDetailPage} />
						<Route path="/faq" component={FAQPage} />
						<Route path="/privacy-policy" component={PrivacyPolicyPage} />
						<Route path="/postal" component={PostalInputPage} />
						<Route path="/auth" component={AuthPage} />
						<Route path="/profile-update" component={ProfileSetupPage} />
						<Route path="/phone-update" component={PhoneNumberInputPage} />

						<Route path="/need-help" component={NeedHelpPage} />

						<Route path="/help-requests/:topic" component={RequestListPage} />

						<Route
							component={AppContainer}
							path={["/heroes", "/masks", "/stats", "/neighbours", "/"]}
						/>
					</Switch>
				</React.Suspense>
			</Router>
		</ApolloProvider>
	);
};

const AppRoot = () => {
	return (
		<ServiceWorkerProvider>
			<Provider store={StoreConfig.store}>
				<PersistGate
					loading={<LoadingComponent />}
					persistor={StoreConfig.persistor}
				>
					<App />
				</PersistGate>
			</Provider>
		</ServiceWorkerProvider>
	);
};

export default AppRoot;
