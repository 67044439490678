import axios from "config/axios";

import ActionTypes from "./actionTypes";

export const logout = () => dispatch => {
	dispatch({
		type: ActionTypes.LOGOUT
	});
};

export const updateUser = user => dispatch =>
	dispatch({
		type: ActionTypes.UPDATE_USER,
		payload: user
	});

export const emailLogin = (email, password, throwError = false) => dispatch => {
	return axios
		.post("/login", {
			email,
			password
		})
		.then(res => {
			if (!res?.data?.token) {
				throw new Error("Empty message");
			}
			dispatch({
				type: ActionTypes.EMAIL_LOGIN_SUCCESS,
				payload: res.data
			});
			return res.data;
		})
		.catch(error => {
			dispatch({
				type: ActionTypes.EMAIL_LOGIN_FAILURE,
				payload: error
			});
			if (throwError) {
				throw error;
			}
		});
};

export const firebaseLogin = (values, throwError = false) => dispatch => {
	return axios
		.post("/firebaseAuth", values)
		.then(res => {
			if (!res?.data?.token) {
				throw new Error("Empty message");
			}
			dispatch({
				type: ActionTypes.FIREBASE_LOGIN_SUCCESS,
				payload: res.data
			});
			return res.data;
		})
		.catch(error => {
			dispatch({
				type: ActionTypes.FIREBASE_LOGIN_FAILURE,
				payload: error
			});
			if (throwError) {
				throw error;
			}
		});
};
