import createAsyncAction from 'shared/utils/asyncAction'

const LOGOUT = 'LOGOUT';
const UPDATE_USER = 'UPDATE_USER';
const EMAIL_LOGIN = 'EMAIL_LOGIN';
const FIREBASE_LOGIN = 'FIREBASE_LOGIN';

export default {
    LOGOUT,
    UPDATE_USER,
    ...createAsyncAction(EMAIL_LOGIN),
    ...createAsyncAction(FIREBASE_LOGIN)
}