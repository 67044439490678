let API_URL = 'https://api.goodhood.sg/ncov2019'
if (process.env.NODE_ENV === 'development') {
    API_URL = 'http://localhost:3005'
}

const GRAPHQL_URL = '//api.goodhood.sg/v1/graphql';
const AUTH_URL = 'https://api.goodhood.sg/auth';

const SLACK_CRASH_REPORT_URL = 'https://hooks.slack.com/services/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'
export {
    API_URL,
    SLACK_CRASH_REPORT_URL,
    GRAPHQL_URL,
    AUTH_URL
}